import { AxiosInstance } from 'axios';

import { API_URL } from '@/constants';
import axios from 'axios';

export function ensureHttpsProtocol(url: string) {
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  } else {
    return url;
  }
}

export const API: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: false,
});

export default API;
